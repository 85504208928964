import React from "react";
import { Tag, Button, DatePicker, Modal, Select, message, InputNumber } from "antd";
import { settlement as settlementApi } from "../../apis";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { StickyHeaderCustomTable, RowAction, CustomTable2 } from "../../components";
import { PlusOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { Filter } from "../../components/Design";
import styled from "styled-components";
import { tugrug } from "../../utils";
import Form from "./Form";
import moment from "moment";
import { useSelector } from "react-redux";
import { operator as operatorApi } from "../../apis";

const TYPES = {
  card   : "BANK_CARD",
  account: "BANK_ACCOUNT"
};

const STATUS = [
  { value: "NEW" },
  { value: "PENDING" },
  { value: "SUCCESS" },
  { value: "FAILED" }
];

export default () => {
  const myTableRef = React.useRef();
  const { s3host } = useSelector(state => state.general);
  const [action, setAction ] = React.useState([]);
  const params = useParams();
  const [options, setOptions] = React.useState([]);

  const [filter, setFilter] = React.useState({
    type                : TYPES.account,
    start_date          : "",
    end_date            : "",
    operator_code_credit: null,
    operator_code_debit : null,
    amount_min          : null,
    amount_max          : null,
  });

  const [filters, setFilters] = React.useState({
    type             : TYPES[params.type],
    start_date       : "",
    end_date         : "",
    operator_code    : null,
    settlement_status: null,
    amount_min       : null,
    amount_max       : null
  });

  React.useEffect(() => {
    operatorApi.list({
      offset: {
        page: 1,
        limit: 100
      },
      filter: {
        is_active: true,
      }
    }).then((res) => {
      if (res) {
        let listData = [];
        for (let index = 0; index < res.rows.length; index++) {
          const selectedData = res.rows[index];
          selectedData.label = selectedData.name;
          selectedData.value = selectedData.code;
          selectedData.tableRef = React.createRef();
          listData.push(selectedData);
        }
        setOptions(listData);
      }
    });
  }, []);

  const dateFilter = (e)=> {
    setFilter({ ...filter, start_date: e && moment(e[0]).startOf("days").format("YYYY-MM-DDTHH:mm"), end_date: e && moment(e[1]).endOf("days").format("YYYY-MM-DDTHH:mm") });
  };

  const dateFilter2 = (e)=> {
    setFilter({ ...filter, operator_code: e });
  };

  const dateFilter3 = (e)=> {
    setFilter({ ...filter, settlement_status: e });
  };

  const onChangeMin = (value) => {
    setFilter({ ...filter, amount_min: value });
  };

  const onChangeMax = (value) => {
    setFilter({ ...filter, amount_max: value });
  };

  const onAction = async (type, row) => {
    switch (type) {
      case "download": {
        window.open(s3host + row.file_url, "_blank");
        break;
      }
      default:
    }
  };

  const search = () => {
    setFilters(filter);
  };

  const onCancel = (reload) => {
    setAction([]);

    if (reload)
      myTableRef.current.reload();
  };

  const onClear = () => {
    const nullFilter = {
      type             : TYPES[params.type],
      start_date       : undefined,
      end_date         : undefined,
      operator_code    : null,
      settlement_status: null,
      amount_min       : null,
      amount_max       : null
    };
    setFilter(nullFilter);
    setFilters(nullFilter);
  };

  // React.useEffect(() => {
  //  if (params.type)
  //    setFilters(state => ({
  //      ...state,
  //      type: TYPES[params.type]
  //    }));
  // }, [params.type]);

  return (
    <PageContainer>
      <PageHeader title="Шимтгэл тооцоолол" extra={[<Button type="primary" onClick={() => setAction(["settlement"])} icon={<PlusOutlined />}>Шимтгэл тооцоолох</Button>]}/>
      <PageContent>
        <Filter
          extra={[
            <Button onClick={search}>Хайлт хийх</Button>,
            <Button onClick={onClear}>Цэвэрлэх</Button>,
          ]}
          children={[
            <DatePicker.RangePicker
              value={[
                filter.start_date && moment(filter.start_date),
                filter.end_date && moment(filter.end_date)
              ]}
              onChange={dateFilter}
            />,
        <Select
          style={{ width: "200px" }}
          options={options}
          placeholder="Оператор"
          maxTagCount="responsive"
          value={filter.operator_code}
          onChange={(val) => {
            dateFilter2(val);
          }}
          optionRender={(option) => {
            return (
              <Space>
                <span role="img" aria-label={option.regUserId}>
                  {option.regUserId}
                </span>
              </Space>
            );
          }}
        />, <Select
          // mode="multiple"
          style={{ width: "200px" }}
          options={STATUS}
          placeholder="Төлөв"
          maxTagCount="responsive"
          value={filter.settlement_status}
          onChange={(val) => {
            dateFilter3(val);
          }}
          optionRender={(option) => {
            return (
              <Space>
                <span role="img" aria-label={option}>
                  {option}
                </span>
              </Space>
            );
          }}
        />, <InputNumber placeholder="Бага дүн" min={1} defaultValue={null} value={filter.amount_min} onChange={onChangeMin} />, <InputNumber placeholder="Их дүн" min={1} defaultValue={null} value={filter.amount_max} onChange={onChangeMax} /> ]}>
        </Filter>
        {/* <div style={{ height: 1 }}>
          <Table
            pagination={false}
            ref={myTableRef}
            filters={filters}
            loadData={settlementApi.list}
            thead={() => (
              <thead className="ant-table-thead sticky-header">
                <tr>
                  <th className="ant-table-cell" rowSpan={2}>№</th>
                  <th className="ant-table-cell" rowSpan={2}>Харилцагч</th>
                  <th className="ant-table-cell" colSpan={2}>Хугацаа</th>
                  <th className="ant-table-cell" colSpan={2}>Гүйлгээ</th>
                  <th className="ant-table-cell" colSpan={4}>Шимтгэл</th>
                  <th className="ant-table-cell" rowSpan={2}>Төлөв</th>
                  <th className="ant-table-cell" rowSpan={2}>Огноо</th>
                  <th className="ant-table-cell" rowSpan={2} width={100}>Үйлдэл</th>
                </tr>
                <tr>
                  <th className="ant-table-cell">Эхлэх огноо</th>
                  <th className="ant-table-cell">Дуусах огноо</th>
                  <th className="ant-table-cell">Тоо</th>
                  <th className="ant-table-cell">Нийт дүн</th>
                  <th className="ant-table-cell">ACQUIRER</th>
                  <th className="ant-table-cell">ISSUER</th>
                  <th className="ant-table-cell">BOM</th>
                  <th className="ant-table-cell">EPAY</th>
                </tr>
              </thead>
            )} tbody={(row, index) => {
              if (index == 0){
                return (
                  <>
                    <tbody key={index} className="ant-table-tbody display-none">
                      <tr>
                        <td className="ant-table-cell" rowSpan={2}>{index + 1}</td>
                        <td className="ant-table-cell">
                          {row.operator_name}
                        </td>
                        <td className="ant-table-cell" rowSpan={2}>
                          {moment(row.start_date).format("YYYY-MM-DD")}
                        </td>
                        <td className="ant-table-cell" rowSpan={2}>
                          {moment(row.end_date).format("YYYY-MM-DD")}
                        </td>
                        <td className="ant-table-cell" rowSpan={2}>
                          {row.total_count}
                        </td>
                        <td className="ant-table-cell" rowSpan={2}>
                          {tugrug(row.total_amount)}
                        </td>
                        <td className="ant-table-cell" rowSpan={2}>
                          {tugrug(row.acquirer_total_amount)}
                        </td>
                        <td className="ant-table-cell" rowSpan={2}>
                          {tugrug(row.issuer_total_amount)}
                        </td>
                        <td className="ant-table-cell" rowSpan={2}>
                          {tugrug(row.bom_total_amount)}
                        </td>
                        <td className="ant-table-cell" rowSpan={2}>
                          {tugrug(row.epay_total_amount)}
                        </td>
                        <td className="ant-table-cell" rowSpan={2}>
                          <Tag>{row.settlement_status}</Tag>
                        </td>
                        <td className="ant-table-cell" rowSpan={2} style={{ minWidth: 180 }}>{moment(row.settlement_status_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                        <td className="ant-table-cell" rowSpan={2}>
                          <RowAction
                            actions = {{
                              download: "Татах"
                            }}
                            onClick={(key) => onAction(key, row)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="ant-table-cell">
                          <Tag>{row.operator_code}</Tag>
                        </td>
                      </tr>
                    </tbody>
                  </>
                );
              }
            }}/>
        </div> */}
        <TableContainer>
          <Table
            ref={myTableRef}
            filters={filters}
            loadData={settlementApi.list}
            thead={() => (
              <thead className="ant-table-thead sticky">
                <tr>
                  <th className="ant-table-cell" rowSpan={2}>№</th>
                  <th className="ant-table-cell" rowSpan={2}>Харилцагч</th>
                  <th className="ant-table-cell" colSpan={2}>Хугацаа</th>
                  <th className="ant-table-cell" colSpan={2}>Гүйлгээ</th>
                  <th className="ant-table-cell" colSpan={4}>Шимтгэл</th>
                  <th className="ant-table-cell" rowSpan={2}>Төлөв</th>
                  <th className="ant-table-cell" rowSpan={2}>Огноо</th>
                  <th className="ant-table-cell" rowSpan={2} width={100}>Үйлдэл</th>
                </tr>
                <tr>
                  <th className="ant-table-cell">Эхлэх огноо</th>
                  <th className="ant-table-cell">Дуусах огноо</th>
                  <th className="ant-table-cell">Тоо</th>
                  <th className="ant-table-cell">Нийт дүн</th>
                  <th className="ant-table-cell">ACQUIRER</th>
                  <th className="ant-table-cell">ISSUER</th>
                  <th className="ant-table-cell">BOM</th>
                  <th className="ant-table-cell">EPAY</th>
                </tr>
              </thead>
            )} tbody={(row, index, page, limit) => {
              let customIndex = (page * limit) + index - limit + 1;
              return (
                <>
                  <tbody key={index} className="ant-table-tbody">
                    <tr>
                      <td className="ant-table-cell" rowSpan={2}>{customIndex}</td>
                      <td className="ant-table-cell">
                        {row.operator_name}
                      </td>
                      <td className="ant-table-cell" rowSpan={2}>
                        {moment(row.start_date).format("YYYY-MM-DD")}
                      </td>
                      <td className="ant-table-cell" rowSpan={2}>
                        {moment(row.end_date).format("YYYY-MM-DD")}
                      </td>
                      <td className="ant-table-cell" rowSpan={2}>
                        {row.total_count}
                      </td>
                      <td className="ant-table-cell" rowSpan={2}>
                        {tugrug(row.total_amount)}
                      </td>
                      <td className="ant-table-cell" rowSpan={2}>
                        {tugrug(row.acquirer_total_amount)}
                      </td>
                      <td className="ant-table-cell" rowSpan={2}>
                        {tugrug(row.issuer_total_amount)}
                      </td>
                      <td className="ant-table-cell" rowSpan={2}>
                        {tugrug(row.bom_total_amount)}
                      </td>
                      <td className="ant-table-cell" rowSpan={2}>
                        {tugrug(row.epay_total_amount)}
                      </td>
                      <td className="ant-table-cell" rowSpan={2}>
                        <Tag>{row.settlement_status}</Tag>
                      </td>
                      <td className="ant-table-cell" rowSpan={2} style={{ minWidth: 180 }}>{moment(row.settlement_status_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                      <td className="ant-table-cell" rowSpan={2}>
                        <RowAction
                          actions = {{
                            download: "Татах"
                          }}
                          onClick={(key) => onAction(key, row)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="ant-table-cell">
                        <Tag>{row.operator_code}</Tag>
                      </td>
                    </tr>
                  </tbody>
                  <tbody key={" " + index} className="ant-table-tbody">
                    <tr>
                      <td className="ant-table-cell" colSpan={13}>
                        <h4>Тооцооллын мэдээлэл</h4>
                        <table>
                          <thead className="ant-table-thead">
                            <tr>
                              <th className="ant-table-cell fee">Нэмэлт тайлбар</th>
                              <th className="ant-table-cell fee">Тооцоолсон ажилтан</th>
                              <th className="ant-table-cell fee">Тооцоолсон огноо</th>
                            </tr>
                          </thead>
                          <tbody key={index}>
                            <tr>
                              <td className="ant-table-cell fee">{row.note || "-"}</td>
                              <td className="ant-table-cell fee">{row.staff_name || "-"}</td>
                              <td className="ant-table-cell fee">{moment(row.refund_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </>
              );
            }} />
        </TableContainer>
      </PageContent>

      <Modal
        title="Тооцоолол хийх"
        visible={action && action[0]}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <Form onCancel={onCancel} action={action} />
      </Modal>
    </PageContainer>
  );
};

const TableContainer = styled.div`
  overflow: auto;
  max-height: 1000px;
`;

const Table = styled(StickyHeaderCustomTable)`
  .ant-table-cell {
    padding: 8px;
    line-height: 15px;
    &.fee {
      font-size: 14px;
      font-weight: normal;
    }
  }
  .sticky-header {
    position: sticky !important;
    top: 0;
    background: "#fafafa";
    z-index: 1;
  }

  .display-none {
    display: hidden
  }

  .position-absolute {
    position: sticky,
    
  }

  .sticky-row-1 {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 2;
  }
`;