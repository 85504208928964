import { jsPDF } from "jspdf";
import React from "react";
import { Button, message } from "antd";
import autoTable from "jspdf-autotable";
import dayjs from "dayjs";
import OpenSans from "./OpenSans";

const PdfGenerate = ({ data, options, filters }) => {
  const handleGeneratePDF = () => {
    const doc = new jsPDF();
		let currentY = 20;
		const pdfData = [];
		if (data && data.length > 0) {
			data.forEach((e) => {
				const incomingDatas = [];
				const opName = options.find((el) => el.code === e.opCode)?.name;
				const incomingData = e.data.incoming.data;
				incomingData.forEach(el => {
					const date = Object.keys(el)[0];
					const formattedDate = dayjs(date).format("YYYY-MM-DD");
					incomingDatas.push({
						date: formattedDate,
						system: el[date][0].system,
						transType: el[date][0].type,
						transCount: el[date][0].quantity,
						feeAmount: el[date][0].charge,
						transAmount: el[date][0].amount,
					});
				});
				const outgoingDatas = [];
				const outgoingData = e.data.outgoing.data;
				outgoingData.forEach(el => {
					const date = Object.keys(el)[0];
					const formattedDate = dayjs(date).format("YYYY-MM-DD");
					outgoingDatas.push({
						date: formattedDate,
						system: el[date][0].system,
						transType: el[date][0].type,
						transCount: el[date][0].quantity,
						feeAmount: el[date][0].charge,
						transAmount: el[date][0].amount,
					});
				});
				pdfData.push({
					incomingData: incomingDatas,
					outgoingData: outgoingDatas,
					opName: opName,
					incomingTotalData: {
						totalAmount: e.data.incoming.totalAmount,
						totalCharge: e.data.incoming.totalCharge,
						totalQuantity: e.data.incoming.totalQuantity
					},
					outgoingTotalData: {
						totalAmount: e.data.outgoing.totalAmount,
						totalCharge: e.data.outgoing.totalCharge,
						totalQuantity: e.data.outgoing.totalQuantity
					}
				});
			});
		} else {
			return message.error("Дата байхгүй байна...");
		};
		const formattedDate = {
			startDate: dayjs(filters.startDate).format("YYYY.MM.DD"),
			endDate: dayjs(filters.endDate).format("YYYY.MM.DD")
		};
    const img = new Image();
    img.src = "/logo-Epay.png";
		doc.addFileToVFS("OpenSans-Regular.ttf", OpenSans.regular);
		doc.addFont("OpenSans-Regular.ttf", "OpenSans", "normal");
		doc.addFileToVFS("OpenSans-Regular.ttf", OpenSans.bold);
		doc.addFont("OpenSans-Regular.ttf", "OpenSans", "bold");
		doc.setFont("OpenSans");

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const imgData = canvas.toDataURL("image/png");
      doc.addImage(imgData, "PNG", 10, 10, 50, 20);
			doc.setFontSize(20);
      doc.text("Харилцагчийн шимтгэл тооцоо ", doc.internal.pageSize.getWidth() / 2, 35, { align: "center" });
			doc.setFontSize(8);
			doc.text("Тайлангийн хамрах хугацаа", 15, 45);
			doc.text(`Эхэлсэн өдөр: ${formattedDate.startDate}`, 25, 50);
			doc.text(`Дууссан өдөр: ${formattedDate.endDate}`, 25, 55);
			doc.setFontSize(8);
			doc.text(`Тайлан хэвлэсэн огноо: ${dayjs().format("YYYY.MM.DD")}`, 140, 20);
			const pageWidth = doc.internal.pageSize.getWidth();
			const tablewidth = pageWidth - 28.2;
			currentY = 55;
			pdfData.forEach((entry, index) => {
				const estimateHeight = 20;
        if (currentY + estimateHeight > 270) {
          doc.addPage();
          currentY = 20;
        };
				doc.setFontSize(14);
				doc.text(entry.opName, 15, currentY + 10);
				currentY += 10;
				autoTable(doc, {
					startY: currentY + 5,
					head: [["Огноо", "Систем", "Гүйлгээний төрөл", "Гүйлгээний тоо", "Нэхэмжлэх шимтгэл дүн", "Гүйлгээний нийт дүн"]],
					body: [
						[{ content: "Хүлээн авсан гүйлгээ", colSpan: 6, styles: { halign: "left", fontStyle: "bold", fontSize: 10, cellPadding: 3 } }],
						...entry.incomingData.map(e => [e.date, e.system, e.transType, e.transCount, e.feeAmount, e.transAmount]),
						[
							{ content: `${entry.opName} - Нийт хүлээн авсан гүйлгээ`, colSpan: 3, styles: { halign: "left", fontStyle: "bold" } },
							{ content: entry.incomingTotalData.totalQuantity, styles: { fontStyle: "bold" } },
							{ content: entry.incomingTotalData.totalCharge, styles: { fontStyle: "bold" } },
							{ content: entry.incomingTotalData.totalAmount, styles: { fontStyle: "bold" } }
						],
						[{ content: "Илгээсэн гүйлгээ", colSpan: 6, styles: { halign: "left", fontStyle: "bold", fontSize: 10, cellPadding: 3 } }],
						...entry.outgoingData.map(e => [e.date, e.system, e.transType, e.transCount, e.feeAmount, e.transAmount]),
						[
							{ content: `${entry.opName} - Нийт илгээсэн гүйлгээ`, colSpan: 3, styles: { halign: "left", fontStyle: "bold" } },
							{ content: entry.outgoingTotalData.totalQuantity, styles: { fontStyle: "bold" } },
							{ content: entry.outgoingTotalData.totalCharge, styles: { fontStyle: "bold" } },
							{ content: entry.outgoingTotalData.totalAmount, styles: { fontStyle: "bold" } }
						]
					],
					didParseCell: () => {
						doc.setFont("OpenSans");
					},
					headStyles: {
						font: "OpenSans",
						fillColor: "gray",
						fontStyle: "bold",
						lineWidth: 0.2,
						lineColor: [0, 0, 0],
					},
					bodyStyles: {
						font: "OpenSans",
						fontStyle: "normal",
						lineWidth: 0.2,
						lineColor: [0, 0, 0],
					},
					columnStyles: {
            0: { cellWidth: tablewidth / 8, valign: "middle", halign: "center" },
            1: { cellWidth: tablewidth / 8, valign: "middle", halign: "center" },
            2: { cellWidth: tablewidth / 6, valign: "middle", halign: "center" },
						3: { cellWidth: "auto", valign: "middle", halign: "center" },
            4: { cellWidth: "auto", valign: "middle", halign: "center" },
            5: { cellWidth: "auto", valign: "middle", halign: "center" },
          },
          styles: {
            lineWidth: 0.2,
            lineColor: [0, 0, 0],
            overflow: "linebreak",
            fontSize: 8,
          },
					// showHead: "firstPage",
					// pageBreak: "avoid"
				});
				currentY = doc.lastAutoTable.finalY + 10;
				if (currentY > 270) {
					doc.addPage();
					currentY = 20;
				}
			});
			const totalPages = doc.getNumberOfPages();
			for (let i = 1; i <= totalPages; i++) {
				doc.setPage(i);
				const pageHeight = doc.internal.pageSize.getHeight();
				doc.setFontSize(10);
				doc.text(`Page ${i}/${totalPages}`, pageWidth / 2, pageHeight - 10, { align: "center" });
			};
      doc.save("Monthly_Billing_Report.pdf");
    };
  };
  return <Button onClick={handleGeneratePDF}>Generate PDF</Button>;
};

export default PdfGenerate;