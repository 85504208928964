import React, { useState } from "react";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const { RangePicker } = DatePicker;

export default ({ dateFilter, isClear }) => {
  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);

  const disabledDate = (current) => {
    if (!dates || !dates[0]) {
      return false;
    }

    const tooLate = dates[0] && current.diff(dates[0], "days") > 30;
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 30;

    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  React.useEffect(() => {
    if (value) {
      const [startDate, endDate] = value;
      dateFilter({
        startDate: startDate ? dayjs(startDate).startOf("day").format() : null,
        endDate: endDate ? dayjs(endDate).endOf("day").format() : null,
      });
    };
  }, [value]);

  React.useEffect(() => {
    if (isClear) {
      setDates(null);
      setValue(null);
    }
  }, [isClear]);

  return (
    <Space direction="vertical">
      <RangePicker
        value={dates || value}
        disabledDate={disabledDate}
        onCalendarChange={(val) => setDates(val)}
        onChange={(val) => setValue(val)}
        onOpenChange={onOpenChange}
      />
    </Space>
  );
};
