import { saveAs } from "file-saver";

const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

const host = process.env.REACT_APP_REPORT_URL;

export const fetchReportData = async (url, method, accessToken, data) => {
  if (!myHeaders.has("Authorization")) {
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
  }
  let response = await fetch(`${host}${url}`, {
    method : method,
    headers: myHeaders,
    body   : JSON.stringify(data),
  });
  let textResponse = await response.text();
  return JSON.parse(textResponse);
};

export const fetchReportFile = async (url, method, accessToken, data, name) => {
  if (!myHeaders.has("Authorization")) {
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
  }
  let response = await fetch(`${host}${url}`, {
    method : method,
    headers: myHeaders,
    body   : JSON.stringify(data),
  });
  try {
    const contentType = response.headers.get("content-type");

    if (
      contentType &&
      (contentType.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") ||
        contentType.includes("application/octet-stream"))
    ) {
      const blob = await response.blob();
      saveAs(blob, `${name}.xlsx`);
    } else {
      throw new Error(`Unexpected content type: ${contentType}`);
    }
  } catch (error) {
    console.log("fetchReportFile", error);
  }
};
