import { jsPDF } from "jspdf";
import React from "react";
import { Button, message } from "antd";
import autoTable from "jspdf-autotable";
import dayjs from "dayjs";
import OpenSans from "./OpenSans";

const PdfGenerate = ({ data, options, filters, reportType }) => {
  const handleGeneratePDF = () => {
    const doc = new jsPDF();
		let currentY = 20;
		let pdfData = [];
		if (data && data.length > 0) {
			pdfData = data.map((current) => ({
				data : current.data.map(el => {
					return {
						date: dayjs(el.date).format("YYYY-MM-DD"),
						debitCount: el.debitCount,
						creditCount: el.creditCount,
						debitAmount: el.debitAmount,
						creditAmount: el.creditAmount
					};
				}),
				totals : [{
					date: "Нийт",
					debitCount: current.totals.totalDebitCount,
					creditCount: current.totals.totalCreditCount,
					debitAmount: current.totals.totalDebitAmount,
					creditAmount: current.totals.totalCreditAmount
				}],
				opName : options.find((el) => el.code === current.opCode)?.name
			}), []);
		} else {
			return message.error("Дата байхгүй байна...");
		};
		const formattedDate = {
			startDate: dayjs(filters.startDate).format("YYYY.MM.DD"),
			endDate: dayjs(filters.endDate).format("YYYY.MM.DD")
		};
    const img = new Image();
    img.src = "/logo-Epay.png";
		doc.addFileToVFS("OpenSans-Regular.ttf", OpenSans.regular);
		doc.addFont("OpenSans-Regular.ttf", "OpenSans", "normal");
		doc.addFileToVFS("OpenSans-Regular.ttf", OpenSans.bold);
		doc.addFont("OpenSans-Regular.ttf", "OpenSans", "bold");
		doc.setFont("OpenSans");

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const imgData = canvas.toDataURL("image/png");
      doc.addImage(imgData, "PNG", 10, 10, 50, 20);
			doc.setFontSize(20);
      doc.text(`ePay-ийн нийлбэр ${reportType === "SUCCESS" ? "амжилттай" : "амжилтгүй"} гүйлгээний тайлан`, doc.internal.pageSize.getWidth() / 2, 35, { align: "center" });
			doc.setFontSize(8);
			doc.text("Тайлангийн хамрах хугацаа", 15, 45);
			doc.text(`Эхэлсэн өдөр: ${formattedDate.startDate}`, 25, 50);
			doc.text(`Дууссан өдөр: ${formattedDate.endDate}`, 25, 55);
			doc.setFontSize(8);
			doc.text(`Тайлан хэвлэсэн огноо: ${dayjs().format("YYYY.MM.DD")}`, 140, 20);

			currentY = 55;
			pdfData.forEach((entry, index) => {
				const estimateHeight = 20;
        if (currentY + estimateHeight > 270) {
          doc.addPage();
          currentY = 20;
        };
				doc.setFontSize(14);
				doc.text(entry.opName, 15, currentY + 10);
				currentY += 10;
				autoTable(doc, {
					startY: currentY + 5,
					head: [["Огноо", "Дебит гүйлгээний тоо", "Кредит гүйлгээний тоо", "Дебит гүйлгээний дүн", "Кредит гүйлгээний дүн"]],
					body: [
						...entry.data.map(e => [e.date, e.debitCount, e.creditCount, e.debitAmount, e.creditAmount]),
						[
							{ content: entry.totals[0].date, styles: { fontStyle: "bold" } },
							{ content: entry.totals[0].debitCount, styles: { fontStyle: "bold" } },
							{ content: entry.totals[0].creditCount, styles: { fontStyle: "bold" } },
							{ content: entry.totals[0].debitAmount, styles: { fontStyle: "bold" } },
							{ content: entry.totals[0].creditAmount, styles: { fontStyle: "bold" } }
						]
					],
					didParseCell: () => {
						doc.setFont("OpenSans");
					},
					headStyles: {
						font: "OpenSans",
						fillColor: "gray",
						fontStyle: "bold",
						lineWidth: 0.2,
						lineColor: [0, 0, 0],
					},
					bodyStyles: {
						font: "OpenSans",
						fontStyle: "normal",
						lineWidth: 0.2,
						lineColor: [0, 0, 0],
					},
					columnStyles: {
            0: { cellWidth: 30, valign: "middle", halign: "center" },
            1: { cellWidth: "auto", valign: "middle", halign: "center" },
            2: { cellWidth: "auto", valign: "middle", halign: "center" },
						3: { cellWidth: "auto", valign: "middle", halign: "center" },
						4: { cellWidth: "auto", valign: "middle", halign: "center" },
          },
          styles: {
            lineWidth: 0.2,
            lineColor: [0, 0, 0],
            overflow: "linebreak",
            fontSize: 8,
          },
				});
				currentY = doc.lastAutoTable.finalY + 10;
				if (currentY > 270) {
					doc.addPage();
					currentY = 20;
				}
			});
			const totalPages = doc.getNumberOfPages();
			for (let i = 1; i <= totalPages; i++) {
				doc.setPage(i);
				const pageHeight = doc.internal.pageSize.getHeight();
				doc.setFontSize(10);
				doc.text(`Page ${i}/${totalPages}`, doc.internal.pageSize.getWidth() / 2, pageHeight - 10, { align: "center" });
			};
      doc.save("Monthly_Transaction_Report.pdf");
    };
  };
  return <Button onClick={handleGeneratePDF}>Generate PDF</Button>;
};

export default PdfGenerate;