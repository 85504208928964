import React from "react";
import { Button, Select, message } from "antd";
import { PageContainer, PageHeader, PageContent } from "../Layout";
import { Filter } from "../Design";
import styled from "styled-components";
import { fetchReportData, fetchReportFile } from "../../utils/reportRequest";
import { useSelector } from "react-redux";
import monthlyBillingTable from "../MyTable/monthlyBillingInvoiceTable";
import { operator as operatorApi } from "../../apis";
import DateFilter from "../Filter/TimeFilter";
import PDFMonthlyBill from "../PDFGenerator/PDFMonthlyBill";

export default ({ reportType, fileName, reportName, isSuccess }) => {
  const { accessToken } = useSelector((state) => state.auth);
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState([]);
  const [subValue, setSubValue] = React.useState([]);
  const [isSearch, setIsSearch] = React.useState(false);
  const [isClear, setIsClear] = React.useState(false);
  const [aggregatedData, setAggregatedData] = React.useState([]);
  const [filters, setFilters] = React.useState({
    startDate: null,
    endDate  : null,
  });

  const dateFilter = (e) => {
    setFilters({
      startDate: e.startDate,
      endDate  : e.endDate,
    });
    setIsClear(false);
  };

  const onClear = () => {
    setFilters({
      startDate: null,
      endDate  : null,
    });
    setIsClear(true);
    setSubValue([]);
    setValue([]);
  };

  const onSearch = () => {
    if ((filters.startDate && filters.endDate) !== null) {
      if (subValue.length > 0) {
        setIsSearch(true);
        setValue(subValue);
      } else {
        message.error("Оператор сонгоно уу");
      }
    } else {
      message.error("Эхлэх болон дуусах өдрийг оруулна уу");
    };
  };

  const getData = (optionValue) => {
    const idx = options.findIndex((option) => option.value === optionValue);
    // if (options[idx].tableRef.current != null) {
    //  options[idx].tableRef.current.setLoading(true);
    //  options[idx].tablePageData.request.pageNo =
    //    options[idx].tableRef.current.getPage();
    //  options[idx].tablePageData.request.perPage =
    //    options[idx].tableRef.current.getLimit();
    // }
    fetchReportData("/report/monthly_bill", "POST", accessToken, {
      // request     : options[idx].tablePageData.request,
      ...filters,
      operatorCode: options[idx].code,
      transactionStatus: "SUCCESS",
      excel:"false"
    })
      .then((data) => {
        if (data.responseCode) {
          options[idx].displayData = data.data;
          setOptions([...options]);
          setAggregatedData((prevData) => [...prevData, { data: data.data, opCode: optionValue }]);
        }
        if (options[idx].tableRef.current != null) {
          options[idx].tableRef.current.setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err ", err);
        if (options[idx].tableRef.current != null) {
          options[idx].tableRef.current.setLoading(false);
        }
      });
  };

  React.useEffect(() => {
    const allIncluded = value.includes("all");
    if ((options.length - 1) === value.length && !allIncluded) {
      setValue([...value, "all"]);
    } else if (allIncluded && value.length !== options.length) {
      setValue(value.filter((el) => el !== "all"));
    }
  }, [value, options.length]);

  React.useEffect(() => {
    operatorApi.list({
      offset: {
        page: 1,
        limit: 100
      },
      filter: {
        is_active: true,
      }
    }).then((res) => {
      const listData = [];
      if (res) {
        for (let index = 0; index < res.rows.length; index++) {
          const selectedData = res.rows[index];
          selectedData.label = selectedData.name;
          selectedData.value = selectedData.code;
          selectedData.tableRef = React.createRef();
          selectedData.displayData = null;
          selectedData.tablePageData = {
            request: {
              pageNo : 1,
              perPage: 5,
              sort   : "",
            },
          };
          selectedData.headerIndexes = [];
          selectedData.setHeaderIndexes = (value) => {
            selectedData.headerIndexes = [
              ...(selectedData.headerIndexes, value),
            ];
          };
          selectedData.subheaderIndexes = [];
          selectedData.setSubheaderIndexes = (value) => {
            selectedData.subheaderIndexes = [
              ...selectedData.subheaderIndexes,
              value,
            ];
          };
          listData.push(selectedData);
        }
        if (listData.length > 0){
          let data = {};
          data.label = "Бүгд";
          data.value = "all";
          listData.unshift(data);
        }
        setOptions(listData);
      }
    });
  }, []);

  React.useEffect(() => {
    if (value.length > 0 && filters.startDate !== null && filters.endDate !== null) {
      value.forEach((el) => {
        getData(el);
      });
    }
  }, [isSearch, value]);

  return (
    <PageContainer>
      <PageHeader title={reportName} />
      <PageContent>
        <Filter>
          <div style={{ display: "flex", flex: "1 1 auto", gap: "10px", marginRight: "100px" }}>
            <DateFilter isClear={isClear} dateFilter={dateFilter}/>
            <Select
              key="DropDown1"
              mode="multiple"
              style={{ width: "200px" }}
              options={options}
              placeholder="Харилцагч сонгоно уу..."
              maxTagCount="responsive"
              value={subValue}
              onChange={(val) => {
                if (
                  filters.startDate === "" ||
                  filters.startDate === null ||
                  filters.endDate === "" ||
                  filters.endDate === null
                ) {
                  message.error("Эхлэх хугацаа, дуусах хугацааг сонгоно уу.");
                  return;
                }
                const lostOne = value.filter((item) => !val.includes(item));
                if (lostOne.length > 0) {
                  if (lostOne[0] === "all") {
                    if (value.length === options.length) {
                      for (let index = 0; index < options.length; index++) {
                        const element = options[index];
                        element.displayData = null;
                        element.tablePageData = {
                          request: {
                            pageNo : 1,
                            perPage: 5,
                            sort   : "",
                          },
                        };
                        element.headerIndexes = [];
                        element.subheaderIndexes = [];
                      }
                      setSubValue([]);
                      return;
                    }
                  }
                  const lostOptionIndex = options.findIndex(
                    (option) => option.value === lostOne[0]
                  );
                  options[lostOptionIndex].displayData = null;
                  options[lostOptionIndex].tablePageData = {
                    request: {
                      pageNo : 1,
                      perPage: 5,
                      sort   : "",
                    },
                  };
                  options[lostOptionIndex].headerIndexes = [];
                  options[lostOptionIndex].subheaderIndexes = [];
                }
                const addedOne = val.filter((item) => !value.includes(item));
                  if (addedOne.length > 0) {
                  if (addedOne[0] === "all") {
                    let data = options.filter((item) => item.value !== "all");
                    let unSelectedItems = [];
                    data.forEach((el) => {
                      const found = value.some((item) => el.value === item);
                      if (!found) {
                        unSelectedItems.push(el);
                      }
                    });
                    setSubValue([...value, ...unSelectedItems.map((el) => el.value)]);
                    return;
                  }
                  }
                setSubValue(val);
              }}
              optionRender={(option) => {
                return (
                  <Space>
                    <span role="img" aria-label={option.regUserId}>
                      {option.regUserId}
                    </span>
                  </Space>
                );
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              key="SearchButton"
              onClick={onSearch}
            >Хайлт хийх</Button>
            <Button
              key="ClearButton2"
              onClick={onClear}
            >Цэвэрлэх</Button>
            <Button
              key="DownloadButton1"
              style={{ backgroundColor: "green", color: "white" }}
              onClick={() => {
                value
                  .filter((el) => el !== "all")
                    .forEach((opCode) => {
                      const selectedOption = options.find((option) => option.value === opCode);
                      if (selectedOption) {
                        fetchReportFile(
                          "/report/monthly_bill?xlsx=true",
                          "POST",
                          accessToken,
                          {
                            ...filters,
                            operatorCode     : opCode,
                            transactionStatus: reportType,
                            excel:"true"
                          },
                          `monthly_billing_invoice_${selectedOption.nameEn}`.toLowerCase()
                        );
                      }
                    });
              }}
            >
              Татах
            </Button>
            <PDFMonthlyBill data={aggregatedData || null} options={options} filters={filters}/>
          </div>
        </Filter>
        {value.map((el, index) => {
          const selectedOption = options.find((option) => option.value === el);
          if (selectedOption.value === "all"){
            return <></>;
          }
          return (
            <React.Fragment>
              <TableContainer >
                <Table
                  ref={selectedOption.tableRef}
                  initialLoading={true}
                  filters={filters}
                  data={selectedOption.displayData}
                  paricipment={selectedOption.name}
                  el={el}
                  setHeaderIndexes={selectedOption.setHeaderIndexes}
                  setSubheaderIndexes={selectedOption.setSubheaderIndexes}
                  perPage={selectedOption.tablePageData.request.perPage}
                  rowKey={(record) => record?.rowKey}
                  getData={getData}
                  value={value}
                  headerIndexes={selectedOption.headerIndexes}
                  subheaderIndexes={selectedOption.subheaderIndexes}
                  isSuccess={isSuccess}
                />
              </TableContainer>
              <div style={{ height: "30px" }}></div>
            </React.Fragment>
          );
        })}
      </PageContent>
    </PageContainer>
  );
};

const TableContainer = styled.div`
  margin-top: 20px;
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th, td {
    padding: 10px;
    border: 1px solid #ddd;
  }

  th {
    background-color: #f4f4f4;
  }

  /* Print-specific styles */
  @media print {
    th, td {
      font-size: 12px;
      padding: 8px;
    }
    
    body {
      margin: 0;
      padding: 0;
      width: auto;
    }
    
    /* Hide certain elements during print */
    button {
      display: none;
    }

    .filter-container {
      display: none;
    }
  }
`;

const Table = styled(monthlyBillingTable)`
  .ant-table-cell {
    padding: 0px;
    line-height: 15px;
  }
  .ant-table-thead .ant-table-cell {
    padding: 8px;
  }
  .ant-table-summary .ant-table-cell {
    padding: 8px;
  }
  .header-cell {
    text-align: left;
    background-color: #f5f5f5;
    font-weight: bold;
  }
  .subheader-cell {
    padding: 8px;
    padding-left: 40px;
    font-weight: bold;
  }
  .subvalue-cell {
    padding: 8px;
    font-weight: bold;
  }
  .cell-center {
    text-align: center;
  }
`;